
































































































import BaseSkeletonLine from "../../components/Base/BaseSkeletonLine.vue";
import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseWrapper from "@/components/Base/BaseWrapper.vue";
import SharedCompaniesProfileAvatar from "@/components/Shared/Companies/ProfileAvatar.vue";
import SharedCompaniesProfileData from "@/components/Shared/Companies/ProfileData.vue";
import SharedProposalListItem from "@/components/Shared/Proposal/ListItem.vue";
import { toDisplayName } from "@/helpers/Formatter";
import { Component, Prop, Vue } from "vue-property-decorator";
import { sortArray } from "@/helpers/arrayHelper";

@Component({
    components: {
        BaseWrapper,
        BaseCard,
        SharedProposalListItem,
        SharedCompaniesProfileData,
        SharedCompaniesProfileAvatar,
        BaseAvatar,
        BaseSkeletonLine,
    },
    metaInfo() {
        const proposer = this.$store.state.users.user;

        const page = "Profil - ";
        const title = page + toDisplayName(proposer);

        return {
            title: title,
        };
    },
})
export default class AppProposerId extends Vue {
    @Prop() proposerId!: string;

    public isLoading: boolean = false;

    get user() {
        return this.$store.state.users.user;
    }

    get sortedProposals() {
        if (!this.user) return [];

        let proposals: any[] = this.user.proposals;
        let sortedArray = sortArray(proposals, "created_at", true);

        return sortedArray;
    }

    async mounted() {
        this.isLoading = true;

        const user = await this.$store.dispatch(
            "users/getOneUser",
            this.proposerId
        );

        setTimeout(() => {
            this.isLoading = false;
        }, 200);
    }

    get proposalsCount() {
        let count: number = 0;
        const isProposalsExist: boolean =
            this.user && this.user.proposals && this.user.proposals.length;

        if (isProposalsExist) count = this.user.proposals.length;

        return count;
    }

    get proposerStatistics() {
        let proposerStatistics: any[] = [
            {
                text: "proposal dibuat",
                value: this.proposalsCount,
                icon: "mdi-clipboard-list",
            },
        ];

        return proposerStatistics;
    }

    get snsAccounts() {
        let snsAccounts: any[] = [];
        const isUserExist: boolean = !!this.user;

        if (!isUserExist) return snsAccounts;

        snsAccounts = [
            {
                type: "WhatsApp",
                username: this.user.profile.phone_number || null,
            },
            {
                type: "Instagram",
                username: this.user.profile.instagram_account || null,
            },
            {
                type: "Twitter",
                username: this.user.profile.twitter_account || null,
            },
            { type: "Website", username: this.user.profile.website || null },
        ];

        return snsAccounts;
    }

    get useGutter() {
        return this.$vuetify.breakpoint.mdAndUp;
    }

    snsIcon(sns: any) {
        let icon = "mdi-whatsapp";

        if (sns.type === "Facebook") icon = "mdi-facebook";
        if (sns.type === "Instagram") icon = "mdi-instagram";
        if (sns.type === "Twitter") icon = "mdi-twitter";
        if (sns.type === "Website") icon = "mdi-web";

        return icon;
    }

    snsValue(sns: any) {
        let value = sns.username;

        if (sns.type === "Facebook") value = "/" + sns.username;
        if (sns.type === "Instagram") value = "@" + sns.username;
        if (sns.type === "Twitter") value = "@" + sns.username;
        if (sns.type === "Website") value = sns.username;

        return value;
    }
}
